import React, {Component} from 'react';
import './DualModeBar.scss';
import TopAppBar, {TopAppBarIcon, TopAppBarRow, TopAppBarSection, TopAppBarTitle} from '@material/react-top-app-bar';

export default class DualModeBar extends Component {
    render() {
        const selectedTypeStyle = this.props.selectedType ? {
            background: this.props.selectedType.color
        } : null;
        return (
            <TopAppBar className="DualModeBar" fixed>
                <TopAppBarRow>
                    <TopAppBarSection align="start">
                        <TopAppBarTitle className="selected-title">Selected:</TopAppBarTitle>
                        {this.props.selectedType &&
                        <div className="selected-type"
                             style={selectedTypeStyle}>
                            <p>{this.props.selectedType.name}</p>
                        </div>
                        }
                    </TopAppBarSection>
                    <TopAppBarSection align="end" role="toolbar">
                        <TopAppBarIcon actionItem tabIndex={0}>
                            <span onClick={this.props.onSingleButtonClick}>SINGLE MODE</span>
                        </TopAppBarIcon>
                    </TopAppBarSection>
                </TopAppBarRow>
            </TopAppBar>
        )
    }
}