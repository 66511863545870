import React, {Component} from 'react';
import './ListItem.css';

export default class ListItem extends Component {
    render() {
        const style = {
            background: this.props.type.color
        };
        const className = "ListItem" + (this.props.onClick ? " mdc-ripple-surface" : "");
        return (
            <div className={className} onClick={this.props.onClick} style={style}>
                {this.props.type.name}
            </div>
        )
    }
}