import React, {Component} from 'react';
import ListItem from './ListItem';
import './List.css';

export default class List extends Component {
    render() {
        const items = this.props.types.map((type) => {
            return (
                <li key={type.type.ordinal}>
                    <ListItem type={type} onClick={this.props.onItemClick ? () => this.props.onItemClick(type) : null}/>
                </li>
            );
        });

        return (
            <div className="List">
                <ul>{items}</ul>
            </div>
        )
    }
}