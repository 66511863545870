import React, {Component} from 'react';
import List from './List';
import {PokemonPresenter} from './compiledKotlin/Pokemon';
import './Detail.css';

export default class Detail extends Component {
    getRenderedTypeDiv(types, header) {
        if (types.length > 0) {
            const typeArray = types.map((type) => PokemonPresenter.getTypeById(type.ordinal))
                .sort((a, b) => a.name.localeCompare(b.name));
            return (
                <div className="section">
                    <p className="sectionHeader">{header}</p>
                    <List types={typeArray}
                          onItemClick={this.props.onItemClick ? (type) => this.props.onItemClick(type) : null}/>
                </div>
            );
        } else {
            return null
        }
    }

    render() {
        let type;
        if (this.props.type2) {
            // Two types so combine their weaknesses/strengths
            type = PokemonPresenter.combineTypes(this.props.type1, this.props.type2);
        } else {
            type = this.props.type1;
        }

        const weakAgainstDiv = this.getRenderedTypeDiv(type.weakAgainst, "Weak against");
        const strongAgainstDiv = this.getRenderedTypeDiv(type.superEffectiveAgainst, "Super effective against");
        const notVeryEffectiveAgainstDiv = this.getRenderedTypeDiv(type.notVeryEffectiveAgainst, "Not very effective against");
        const ineffectiveAgainstDiv = this.getRenderedTypeDiv(type.noEffectAgainst, "No effect against");
        let extraNotesDiv = null;
        if (type.extraNotes) {
            extraNotesDiv = (
                <p className="extraNotes">Note: {type.extraNotes}</p>
            );
        }

        return (
            <div className="Type">
                {weakAgainstDiv}
                {strongAgainstDiv}
                {notVeryEffectiveAgainstDiv}
                {ineffectiveAgainstDiv}
                {extraNotesDiv}
            </div>
        )
    }
}