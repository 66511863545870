import React, {Component} from 'react';
import List from './List';
import Detail from './Detail';
import {PokemonPresenter} from './compiledKotlin/Pokemon';
import TextField, {Input} from '@material/react-text-field';
import TopAppBar, {
    TopAppBarFixedAdjust,
    TopAppBarIcon,
    TopAppBarRow,
    TopAppBarSection,
    TopAppBarTitle
} from '@material/react-top-app-bar';
import MaterialIcon from '@material/react-material-icon';
import './App.scss';
import DualModeBar from "./DualModeBar";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentType: null,
            types: PokemonPresenter.getAllTypes(),
            query: "",
            dualMode: false,
            selectedDualModeTypes: [],
            searchIconExpanded: false
        };
    }

    onBackPressed = () => {
        this.setState({
            currentType: null,
            selectedDualModeTypes: []
        })
    };

    onItemClick = (type) => {
        if (this.state.dualMode) {
            if (this.state.selectedDualModeTypes[0]) {
                const type1 = this.state.selectedDualModeTypes[0];
                this.setState({
                    selectedDualModeTypes: [type1, type]
                })
            } else {
                this.setState({
                    selectedDualModeTypes: [type]
                })
            }

        } else {
            this.setState({
                currentType: type
            })
        }
    };

    onSearchTextChanged = (event) => {
        const text = event.target.value;
        let types;
        if (text.length > 0) {
            types = PokemonPresenter.searchForTypesByName(text);
        } else {
            types = PokemonPresenter.getAllTypes();
        }
        this.setState({
            types: types,
            query: text
        })
    };

    onSearchBoxEnter = (event) => {
        // Keycode 13 is the enter key
        if (event.keyCode === 13) {
            event.target.blur();
        }
    };

    onSearchClear = () => {
        const event = {
            target: {
                value: ""
            }
        };
        this.onSearchTextChanged(event);
        this.setState({
            searchIconExpanded: false
        });
    };

    onDualModeClicked = () => {
        this.setState({
            dualMode: true
        });
    };

    onSingleModeClicked = () => {
        this.setState({
            dualMode: false,
            selectedDualModeTypes: []
        });
    };

    onSearchClicked = () => {
        this.setState({
            searchIconExpanded: true
        });
    };

    render() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        if (this.state.currentType
            || (this.state.dualMode && this.state.selectedDualModeTypes.length > 1)) {
            let title;
            if (this.state.dualMode && this.state.selectedDualModeTypes.length > 1) {
                title = this.state.selectedDualModeTypes[1].name + " | " + this.state.selectedDualModeTypes[0].name;
            } else {
                title = this.state.currentType.name;
            }
            return (
                <div className="App">
                    <TopAppBar fixed>
                        <TopAppBarRow>
                            <TopAppBarSection align="start">
                                <TopAppBarIcon navIcon tabIndex={0}>
                                    <MaterialIcon hasRipple
                                                  aria-label="back"
                                                  icon="arrow_back"
                                                  onClick={this.onBackPressed}/>
                                </TopAppBarIcon>
                            </TopAppBarSection>
                        </TopAppBarRow>
                    </TopAppBar>
                    <span className="detail-title">{title}</span>
                    <TopAppBarFixedAdjust>
                        <Detail
                            type1={this.state.currentType ? this.state.currentType : this.state.selectedDualModeTypes[0]}
                            type2={this.state.selectedDualModeTypes[1]}
                            onItemClick={this.state.currentType ? (type) => this.onItemClick(type) : null}/>
                    </TopAppBarFixedAdjust>
                </div>
            );
        } else {
            let types = this.state.types.slice();
            if (this.state.dualMode && this.state.selectedDualModeTypes[0]) {
                // Remove the first selected type from the list of types to choose from
                // if in dual mode
                const index = types.indexOf(this.state.selectedDualModeTypes[0]);
                types.splice(index, 1);
            }
            return (
                <div className="App">
                    {this.state.dualMode ? (
                        <DualModeBar onSingleButtonClick={this.onSingleModeClicked}
                                     selectedType={this.state.selectedDualModeTypes[0]}/>
                    ) : (
                        <TopAppBar className="top-bar" fixed>
                            <TopAppBarRow>
                                {!this.state.searchIconExpanded &&
                                <TopAppBarSection align="start">
                                    <TopAppBarTitle>Pokemon Weaknesses</TopAppBarTitle>
                                </TopAppBarSection>
                                }
                                <TopAppBarSection align="end" role="toolbar">
                                    {this.state.searchIconExpanded ? (
                                        <TextField className="search-container"
                                                   label="Search for type"
                                                   leadingIcon={<i className="material-icons">search</i>}
                                                   trailingIcon={<i className="material-icons">close</i>}
                                                   onTrailingIconSelect={this.onSearchClear}>
                                            <Input onChange={this.onSearchTextChanged}
                                                   onKeyUp={this.onSearchBoxEnter}
                                                   value={this.state.query}/>
                                        </TextField>
                                    ) : (
                                        <TopAppBarIcon actionItem tabIndex={0}>
                                            <MaterialIcon aria-label="search"
                                                          hasRipple
                                                          icon="search"
                                                          onClick={this.onSearchClicked}/>
                                        </TopAppBarIcon>
                                    )}
                                    <TopAppBarIcon actionItem tabIndex={1}>
                                        <span onClick={this.onDualModeClicked}>DUAL MODE</span>
                                    </TopAppBarIcon>
                                </TopAppBarSection>
                            </TopAppBarRow>
                        </TopAppBar>
                    )}
                    <TopAppBarFixedAdjust>
                        <List types={types} onItemClick={(type) => this.onItemClick(type)}/>
                    </TopAppBarFixedAdjust>
                </div>
            );
        }
    }
}

export default App;
