object PokemonPresenter {

    private val TYPES = listOf(
            PokemonType.NORMAL, PokemonType.FIGHTING, PokemonType.FLYING, PokemonType.POISON,
            PokemonType.GROUND, PokemonType.ROCK, PokemonType.BUG, PokemonType.GHOST, PokemonType.STEEL,
            PokemonType.FIRE, PokemonType.WATER, PokemonType.GRASS, PokemonType.ELECTRIC, PokemonType.PSYCHIC,
            PokemonType.ICE, PokemonType.DRAGON, PokemonType.DARK, PokemonType.FAIRY
    ).sortedBy { it.name }.toTypedArray()
    
    fun getAllTypes() = TYPES

    @JsName("searchForTypesByName")
    fun searchForTypesByName(query: String) = TYPES.filter { it.name.contains(query, ignoreCase = true) }.toTypedArray()

    @JsName("getTypeById")
    fun getTypeById(id: Int) = TYPES.find { it.type.ordinal == id }

    @JsName("combineTypes")
    fun combineTypes(type1: PokemonType, type2: PokemonType): PokemonType {
        val combinedSuperEffective = mutableSetOf(*type1.superEffectiveAgainst, *type2.superEffectiveAgainst)
        val combinedNotVeryEffective = mutableSetOf(*type1.notVeryEffectiveAgainst, *type2.notVeryEffectiveAgainst)
        val combinedNoEffect = mutableSetOf(*type1.noEffectAgainst, *type2.noEffectAgainst)
        val combinedWeakAgainst = mutableSetOf(*type1.weakAgainst, *type2.weakAgainst)

        // If super or not-very is in no-effect list, remove from the first two lists
        combinedSuperEffective.union(combinedNoEffect).forEach {
            combinedSuperEffective.remove(it)
        }
        combinedNotVeryEffective.union(combinedNoEffect).forEach {
            combinedNotVeryEffective.remove(it)
        }

        // If super is in not-very list, remove from both (cancelled out)
        val combinedSuperNotVery = combinedSuperEffective.union(combinedNotVeryEffective)
        combinedSuperEffective.removeAll(combinedSuperNotVery)
        combinedNotVeryEffective.removeAll(combinedSuperNotVery)

        return PokemonType(
                type = type1.type,
                color = type1.color,
                superEffectiveAgainst = combinedSuperEffective.toTypedArray(),
                notVeryEffectiveAgainst = combinedNotVeryEffective.toTypedArray(),
                noEffectAgainst = combinedNoEffect.toTypedArray(),
                weakAgainst = combinedWeakAgainst.toTypedArray(),
                extraNotes = type1.extraNotes ?: type2.extraNotes
        )
    }

}